import {LunarUtil} from './LunarUtil';
import {SolarUtil} from './SolarUtil';
import {TaoUtil} from './TaoUtil';
import {FotoUtil} from './FotoUtil';
import {NineStarUtil} from './NineStarUtil';
import {isKey} from "@/lib/Obj";

export interface I18nInterface {
    _DICT_NUMBER: { [key: string]: { [key: string]: string } };
    _OBJS: { [key: string]: { [key: string]: number } };
}

export class I18n {
    private static _DEFAULT_LANG = 'chs';
    private static _LANG: string;
    private static _INIT = false;

    private static _MESSAGES: { [key: string]: { [key: string]: string } } = {
        'chs': {
            'tg.jia': '甲',
            'tg.yi': '乙',
            'tg.bing': '丙',
            'tg.ding': '丁',
            'tg.wu': '戊',
            'tg.ji': '己',
            'tg.geng': '庚',
            'tg.xin': '辛',
            'tg.ren': '壬',
            'tg.gui': '癸',
            'dz.zi': '子',
            'dz.chou': '丑',
            'dz.yin': '寅',
            'dz.mao': '卯',
            'dz.chen': '辰',
            'dz.si': '巳',
            'dz.wu': '午',
            'dz.wei': '未',
            'dz.shen': '申',
            'dz.you': '酉',
            'dz.xu': '戌',
            'dz.hai': '亥',
            'zx.jian': '建',
            'zx.chu': '除',
            'zx.man': '满',
            'zx.ping': '平',
            'zx.ding': '定',
            'zx.zhi': '执',
            'zx.po': '破',
            'zx.wei': '危',
            'zx.cheng': '成',
            'zx.shou': '收',
            'zx.kai': '开',
            'zx.bi': '闭',
            'jz.jiaZi': '甲子',
            'jz.yiChou': '乙丑',
            'jz.bingYin': '丙寅',
            'jz.dingMao': '丁卯',
            'jz.wuChen': '戊辰',
            'jz.jiSi': '己巳',
            'jz.gengWu': '庚午',
            'jz.xinWei': '辛未',
            'jz.renShen': '壬申',
            'jz.guiYou': '癸酉',
            'jz.jiaXu': '甲戌',
            'jz.yiHai': '乙亥',
            'jz.bingZi': '丙子',
            'jz.dingChou': '丁丑',
            'jz.wuYin': '戊寅',
            'jz.jiMao': '己卯',
            'jz.gengChen': '庚辰',
            'jz.xinSi': '辛巳',
            'jz.renWu': '壬午',
            'jz.guiWei': '癸未',
            'jz.jiaShen': '甲申',
            'jz.yiYou': '乙酉',
            'jz.bingXu': '丙戌',
            'jz.dingHai': '丁亥',
            'jz.wuZi': '戊子',
            'jz.jiChou': '己丑',
            'jz.gengYin': '庚寅',
            'jz.xinMao': '辛卯',
            'jz.renChen': '壬辰',
            'jz.guiSi': '癸巳',
            'jz.jiaWu': '甲午',
            'jz.yiWei': '乙未',
            'jz.bingShen': '丙申',
            'jz.dingYou': '丁酉',
            'jz.wuXu': '戊戌',
            'jz.jiHai': '己亥',
            'jz.gengZi': '庚子',
            'jz.xinChou': '辛丑',
            'jz.renYin': '壬寅',
            'jz.guiMao': '癸卯',
            'jz.jiaChen': '甲辰',
            'jz.yiSi': '乙巳',
            'jz.bingWu': '丙午',
            'jz.dingWei': '丁未',
            'jz.wuShen': '戊申',
            'jz.jiYou': '己酉',
            'jz.gengXu': '庚戌',
            'jz.xinHai': '辛亥',
            'jz.renZi': '壬子',
            'jz.guiChou': '癸丑',
            'jz.jiaYin': '甲寅',
            'jz.yiMao': '乙卯',
            'jz.bingChen': '丙辰',
            'jz.dingSi': '丁巳',
            'jz.wuWu': '戊午',
            'jz.jiWei': '己未',
            'jz.gengShen': '庚申',
            'jz.xinYou': '辛酉',
            'jz.renXu': '壬戌',
            'jz.guiHai': '癸亥',
            'sx.rat': '鼠',
            'sx.ox': '牛',
            'sx.tiger': '虎',
            'sx.rabbit': '兔',
            'sx.dragon': '龙',
            'sx.snake': '蛇',
            'sx.horse': '马',
            'sx.goat': '羊',
            'sx.monkey': '猴',
            'sx.rooster': '鸡',
            'sx.dog': '狗',
            'sx.pig': '猪',
            'dw.long': '龙',
            'dw.niu': '牛',
            'dw.gou': '狗',
            'dw.yang': '羊',
            'dw.tu': '兔',
            'dw.shu': '鼠',
            'dw.ji': '鸡',
            'dw.ma': '马',
            'dw.hu': '虎',
            'dw.zhu': '猪',
            'dw.hou': '猴',
            'dw.she': '蛇',
            'dw.huLi': '狐',
            'dw.yan': '燕',
            'dw.bao': '豹',
            'dw.yuan': '猿',
            'dw.yin': '蚓',
            'dw.lu': '鹿',
            'dw.wu': '乌',
            'dw.jiao': '蛟',
            'dw.lang': '狼',
            'dw.fu': '蝠',
            'dw.zhang': '獐',
            'dw.xu': '獝',
            'dw.xie': '獬',
            'dw.han': '犴',
            'dw.he': '貉',
            'dw.zhi': '彘',
            'wx.jin': '金',
            'wx.mu': '木',
            'wx.shui': '水',
            'wx.huo': '火',
            'wx.tu': '土',
            'wx.ri': '日',
            'wx.yue': '月',
            'n.zero': '〇',
            'n.one': '一',
            'n.two': '二',
            'n.three': '三',
            'n.four': '四',
            'n.five': '五',
            'n.six': '六',
            'n.seven': '七',
            'n.eight': '八',
            'n.nine': '九',
            'n.ten': '十',
            'n.eleven': '十一',
            'n.twelve': '十二',
            'd.one': '初一',
            'd.two': '初二',
            'd.three': '初三',
            'd.four': '初四',
            'd.five': '初五',
            'd.six': '初六',
            'd.seven': '初七',
            'd.eight': '初八',
            'd.nine': '初九',
            'd.ten': '初十',
            'd.eleven': '十一',
            'd.twelve': '十二',
            'd.thirteen': '十三',
            'd.fourteen': '十四',
            'd.fifteen': '十五',
            'd.sixteen': '十六',
            'd.seventeen': '十七',
            'd.eighteen': '十八',
            'd.nighteen': '十九',
            'd.twenty': '二十',
            'd.twentyOne': '廿一',
            'd.twentyTwo': '廿二',
            'd.twentyThree': '廿三',
            'd.twentyFour': '廿四',
            'd.twentyFive': '廿五',
            'd.twentySix': '廿六',
            'd.twentySeven': '廿七',
            'd.twentyEight': '廿八',
            'd.twentyNine': '廿九',
            'd.thirty': '三十',
            'm.one': '正',
            'm.two': '二',
            'm.three': '三',
            'm.four': '四',
            'm.five': '五',
            'm.six': '六',
            'm.seven': '七',
            'm.eight': '八',
            'm.nine': '九',
            'm.ten': '十',
            'm.eleven': '冬',
            'm.twelve': '腊',
            'w.sun': '日',
            'w.mon': '一',
            'w.tues': '二',
            'w.wed': '三',
            'w.thur': '四',
            'w.fri': '五',
            'w.sat': '六',
            'xz.aries': '白羊',
            'xz.taurus': '金牛',
            'xz.gemini': '双子',
            'xz.cancer': '巨蟹',
            'xz.leo': '狮子',
            'xz.virgo': '处女',
            'xz.libra': '天秤',
            'xz.scorpio': '天蝎',
            'xz.sagittarius': '射手',
            'xz.capricornus': '摩羯',
            'xz.aquarius': '水瓶',
            'xz.pisces': '双鱼',
            'bg.qian': '乾',
            'bg.kun': '坤',
            'bg.zhen': '震',
            'bg.xun': '巽',
            'bg.kan': '坎',
            'bg.li': '离',
            'bg.gen': '艮',
            'bg.dui': '兑',
            'ps.center': '中',
            'ps.dong': '东',
            'ps.nan': '南',
            'ps.xi': '西',
            'ps.bei': '北',
            'ps.zhong': '中宫',
            'ps.zhengDong': '正东',
            'ps.zhengNan': '正南',
            'ps.zhengXi': '正西',
            'ps.zhengBei': '正北',
            'ps.dongBei': '东北',
            'ps.dongNan': '东南',
            'ps.xiBei': '西北',
            'ps.xiNan': '西南',
            'ps.wai': '外',
            'ps.fangNei': '房内',
            'jq.dongZhi': '冬至',
            'jq.xiaoHan': '小寒',
            'jq.daHan': '大寒',
            'jq.liChun': '立春',
            'jq.yuShui': '雨水',
            'jq.jingZhe': '惊蛰',
            'jq.chunFen': '春分',
            'jq.qingMing': '清明',
            'jq.guYu': '谷雨',
            'jq.liXia': '立夏',
            'jq.xiaoMan': '小满',
            'jq.mangZhong': '芒种',
            'jq.xiaZhi': '夏至',
            'jq.xiaoShu': '小暑',
            'jq.daShu': '大暑',
            'jq.liQiu': '立秋',
            'jq.chuShu': '处暑',
            'jq.baiLu': '白露',
            'jq.qiuFen': '秋分',
            'jq.hanLu': '寒露',
            'jq.shuangJiang': '霜降',
            'jq.liDong': '立冬',
            'jq.xiaoXue': '小雪',
            'jq.daXue': '大雪',
            'sn.qingLong': '青龙',
            'sn.baiHu': '白虎',
            'sn.zhuQue': '朱雀',
            'sn.xuanWu': '玄武',
            'sn.mingTang': '明堂',
            'sn.tianXing': '天刑',
            'sn.tianDe': '天德',
            'sn.jinKui': '金匮',
            'sn.yuTang': '玉堂',
            'sn.siMing': '司命',
            'sn.tianLao': '天牢',
            'sn.gouChen': '勾陈',
            'sn.tianEn': '天恩',
            'sn.muCang': '母仓',
            'sn.shiYang': '时阳',
            'sn.shengQi': '生气',
            'sn.yiHou': '益后',
            'sn.zaiSha': '灾煞',
            'sn.tianHuo': '天火',
            'sn.siJi': '四忌',
            'sn.baLong': '八龙',
            'sn.fuRi': '复日',
            'sn.xuShi': '续世',
            'sn.yueSha': '月煞',
            'sn.yueXu': '月虚',
            'sn.xueZhi': '血支',
            'sn.tianZei': '天贼',
            'sn.wuXu': '五虚',
            'sn.tuFu': '土符',
            'sn.guiJi': '归忌',
            'sn.xueJi': '血忌',
            'sn.yueDe': '月德',
            'sn.yueEn': '月恩',
            'sn.siXiang': '四相',
            'sn.wangRi': '王日',
            'sn.tianCang': '天仓',
            'sn.buJiang': '不将',
            'sn.wuHe': '五合',
            'sn.mingFeiDui': '鸣吠对',
            'sn.yueJian': '月建',
            'sn.xiaoShi': '小时',
            'sn.tuHu': '土府',
            'sn.wangWang': '往亡',
            'sn.yaoAn': '要安',
            'sn.siShen': '死神',
            'sn.tianMa': '天马',
            'sn.jiuHu': '九虎',
            'sn.qiNiao': '七鸟',
            'sn.liuShe': '六蛇',
            'sn.guanRi': '官日',
            'sn.jiQi': '吉期',
            'sn.yuYu': '玉宇',
            'sn.daShi': '大时',
            'sn.daBai': '大败',
            'sn.xianChi': '咸池',
            'sn.shouRi': '守日',
            'sn.tianWu': '天巫',
            'sn.fuDe': '福德',
            'sn.liuYi': '六仪',
            'sn.jinTang': '金堂',
            'sn.yanDui': '厌对',
            'sn.zhaoYao': '招摇',
            'sn.jiuKong': '九空',
            'sn.jiuKan': '九坎',
            'sn.jiuJiao': '九焦',
            'sn.xiangRi': '相日',
            'sn.baoGuang': '宝光',
            'sn.tianGang': '天罡',
            'sn.yueXing': '月刑',
            'sn.yueHai': '月害',
            'sn.youHuo': '游祸',
            'sn.chongRi': '重日',
            'sn.shiDe': '时德',
            'sn.minRi': '民日',
            'sn.sanHe': '三合',
            'sn.linRi': '临日',
            'sn.shiYin': '时阴',
            'sn.mingFei': '鸣吠',
            'sn.siQi': '死气',
            'sn.diNang': '地囊',
            'sn.yueDeHe': '月德合',
            'sn.jingAn': '敬安',
            'sn.puHu': '普护',
            'sn.jieShen': '解神',
            'sn.xiaoHao': '小耗',
            'sn.tianDeHe': '天德合',
            'sn.yueKong': '月空',
            'sn.yiMa': '驿马',
            'sn.tianHou': '天后',
            'sn.chuShen': '除神',
            'sn.yuePo': '月破',
            'sn.daHao': '大耗',
            'sn.wuLi': '五离',
            'sn.yinDe': '阴德',
            'sn.fuSheng': '福生',
            'sn.tianLi': '天吏',
            'sn.zhiSi': '致死',
            'sn.yuanWu': '元武',
            'sn.yangDe': '阳德',
            'sn.tianXi': '天喜',
            'sn.tianYi': '天医',
            'sn.yueYan': '月厌',
            'sn.diHuo': '地火',
            'sn.fourHit': '四击',
            'sn.daSha': '大煞',
            'sn.daHui': '大会',
            'sn.tianYuan': '天愿',
            'sn.liuHe': '六合',
            'sn.wuFu': '五富',
            'sn.shengXin': '圣心',
            'sn.heKui': '河魁',
            'sn.jieSha': '劫煞',
            'sn.siQiong': '四穷',
            'sn.chuShuiLong': '触水龙',
            'sn.baFeng': '八风',
            'sn.tianShe': '天赦',
            'sn.wuMu': '五墓',
            'sn.baZhuan': '八专',
            'sn.yinCuo': '阴错',
            'sn.siHao': '四耗',
            'sn.yangCuo': '阳错',
            'sn.siFei': '四废',
            'sn.sanYin': '三阴',
            'sn.xiaoHui': '小会',
            'sn.yinDaoChongYang': '阴道冲阳',
            'sn.danYin': '单阴',
            'sn.guChen': '孤辰',
            'sn.yinWei': '阴位',
            'sn.xingHen': '行狠',
            'sn.liaoLi': '了戾',
            'sn.jueYin': '绝阴',
            'sn.chunYang': '纯阳',
            'sn.suiBo': '岁薄',
            'sn.yinYangJiaoPo': '阴阳交破',
            'sn.yinYangJuCuo': '阴阳俱错',
            'sn.yinYangJiChong': '阴阳击冲',
            'sn.zhuZhen': '逐阵',
            'sn.yangCuoYinChong': '阳错阴冲',
            'sn.qiFu': '七符',
            'sn.tianGou': '天狗',
            'sn.chengRi': '成日',
            'sn.tianFu': '天符',
            'sn.guYang': '孤阳',
            'sn.jueYang': '绝阳',
            'sn.chunYin': '纯阴',
            'sn.yinShen': '阴神',
            'sn.jieChu': '解除',
            'sn.yangPoYinChong': '阳破阴冲',
            'ss.biJian': '比肩',
            'ss.jieCai': '劫财',
            'ss.shiShen': '食神',
            'ss.shangGuan': '伤官',
            'ss.pianCai': '偏财',
            'ss.zhengCai': '正财',
            'ss.qiSha': '七杀',
            'ss.zhengGuan': '正官',
            'ss.pianYin': '偏印',
            'ss.zhengYin': '正印',
            's.none': '无',
            's.huangDao': '黄道',
            's.heiDao': '黑道',
            's.goodLuck': '吉',
            's.badLuck': '凶',
            's.yin': '阴',
            's.yang': '阳',
            's.white': '白',
            's.black': '黑',
            's.blue': '碧',
            's.green': '绿',
            's.yellow': '黄',
            's.red': '赤',
            's.purple': '紫',
            'jr.chuXi': '除夕',
            'jr.chunJie': '春节',
            'jr.yuanXiao': '元宵节',
            'jr.longTou': '龙头节',
            'jr.duanWu': '端午节',
            'jr.qiXi': '七夕节',
            'jr.zhongQiu': '中秋节',
            'jr.chongYang': '重阳节',
            'jr.laBa': '腊八节',
            'jr.yuanDan': '元旦节',
            'jr.qingRen': '情人节',
            'jr.fuNv': '妇女节',
            'jr.zhiShu': '植树节',
            'jr.xiaoFei': '消费者权益日',
            'jr.wuYi': '劳动节',
            'jr.qingNian': '青年节',
            'jr.erTong': '儿童节',
            'jr.yuRen': '愚人节',
            'jr.jianDang': '建党节',
            'jr.jianJun': '建军节',
            'jr.jiaoShi': '教师节',
            'jr.guoQing': '国庆节',
            'jr.wanShengYe': '万圣节前夜',
            'jr.wanSheng': '万圣节',
            'jr.pingAn': '平安夜',
            'jr.shengDan': '圣诞节',
            'ds.changSheng': '长生',
            'ds.muYu': '沐浴',
            'ds.guanDai': '冠带',
            'ds.linGuan': '临官',
            'ds.diWang': '帝旺',
            'ds.shuai': '衰',
            'ds.bing': '病',
            'ds.si': '死',
            'ds.mu': '墓',
            'ds.jue': '绝',
            'ds.tai': '胎',
            'ds.yang': '养',
            'h.first': '初候',
            'h.second': '二候',
            'h.third': '三候',
            'h.qiuYinJie': '蚯蚓结',
            'h.miJiao': '麋角解',
            'h.shuiQuan': '水泉动',
            'h.yanBei': '雁北乡',
            'h.queShi': '鹊始巢',
            'h.zhiShi': '雉始雊',
            'h.jiShi': '鸡始乳',
            'h.zhengNiao': '征鸟厉疾',
            'h.shuiZe': '水泽腹坚',
            'h.dongFeng': '东风解冻',
            'h.zheChongShiZhen': '蛰虫始振',
            'h.yuZhi': '鱼陟负冰',
            'h.taJi': '獭祭鱼',
            'h.houYan': '候雁北',
            'h.caoMuMengDong': '草木萌动',
            'h.taoShi': '桃始华',
            'h.cangGeng': '仓庚鸣',
            'h.yingHua': '鹰化为鸠',
            'h.xuanNiaoZhi': '玄鸟至',
            'h.leiNai': '雷乃发声',
            'h.shiDian': '始电',
            'h.tongShi': '桐始华',
            'h.tianShu': '田鼠化为鴽',
            'h.hongShi': '虹始见',
            'h.pingShi': '萍始生',
            'h.mingJiu': '鸣鸠拂奇羽',
            'h.daiSheng': '戴胜降于桑',
            'h.louGuo': '蝼蝈鸣',
            'h.qiuYinChu': '蚯蚓出',
            'h.wangGua': '王瓜生',
            'h.kuCai': '苦菜秀',
            'h.miCao': '靡草死',
            'h.maiQiu': '麦秋至',
            'h.tangLang': '螳螂生',
            'h.juShi': '鵙始鸣',
            'h.fanShe': '反舌无声',
            'h.luJia': '鹿角解',
            'h.tiaoShi': '蜩始鸣',
            'h.banXia': '半夏生',
            'h.wenFeng': '温风至',
            'h.xiShuai': '蟋蟀居壁',
            'h.yingShi': '鹰始挚',
            'h.fuCao': '腐草为萤',
            'h.tuRun': '土润溽暑',
            'h.daYu': '大雨行时',
            'h.liangFeng': '凉风至',
            'h.baiLu': '白露降',
            'h.hanChan': '寒蝉鸣',
            'h.yingNai': '鹰乃祭鸟',
            'h.tianDi': '天地始肃',
            'h.heNai': '禾乃登',
            'h.hongYanLai': '鸿雁来',
            'h.xuanNiaoGui': '玄鸟归',
            'h.qunNiao': '群鸟养羞',
            'h.leiShi': '雷始收声',
            'h.zheChongPiHu': '蛰虫坯户',
            'h.shuiShiHe': '水始涸',
            'h.hongYanLaiBin': '鸿雁来宾',
            'h.queRu': '雀入大水为蛤',
            'h.juYou': '菊有黄花',
            'h.caiNai': '豺乃祭兽',
            'h.caoMuHuangLuo': '草木黄落',
            'h.zheChongXianFu': '蛰虫咸俯',
            'h.shuiShiBing': '水始冰',
            'h.diShi': '地始冻',
            'h.zhiRu': '雉入大水为蜃',
            'h.hongCang': '虹藏不见',
            'h.tianQi': '天气上升地气下降',
            'h.biSe': '闭塞而成冬',
            'h.heDan': '鹖鴠不鸣',
            'h.huShi': '虎始交',
            'h.liTing': '荔挺出',
            'ts.zhan': '占',
            'ts.hu': '户',
            'ts.win': '窗',
            'ts.fang': '房',
            'ts.chuang': '床',
            'ts.lu': '炉',
            'ts.zao': '灶',
            'ts.dui': '碓',
            'ts.mo': '磨',
            'ts.xi': '栖',
            'ts.chu': '厨',
            'ts.ce': '厕',
            'ts.cang': '仓',
            'ts.cangKu': '仓库',
            'ts.daMen': '大门',
            'ts.men': '门',
            'ts.tang': '堂',
            'ly.xianSheng': '先胜',
            'ly.xianFu': '先负',
            'ly.youYin': '友引',
            'ly.foMie': '佛灭',
            'ly.daAn': '大安',
            'ly.chiKou': '赤口',
            'yj.jiSi': '祭祀',
            'yj.qiFu': '祈福',
            'yj.qiuSi': '求嗣',
            'yj.kaiGuang': '开光',
            'yj.suHui': '塑绘',
            'yj.qiJiao': '齐醮',
            'yj.zhaiJiao': '斋醮',
            'yj.muYu': '沐浴',
            'yj.chouShen': '酬神',
            'yj.zaoMiao': '造庙',
            'yj.siZhao': '祀灶',
            'yj.fenXiang': '焚香',
            'yj.xieTu': '谢土',
            'yj.chuHuo': '出火',
            'yj.diaoKe': '雕刻',
            'yj.jiaQu': '嫁娶',
            'yj.DingHun': '订婚',
            'yj.naCai': '纳采',
            'yj.wenMing': '问名',
            'yj.naXu': '纳婿',
            'yj.guiNing': '归宁',
            'yj.anChuang': '安床',
            'yj.heZhang': '合帐',
            'yj.guanJi': '冠笄',
            'yj.dingMeng': '订盟',
            'yj.jinRenKou': '进人口',
            'yj.caiYi': '裁衣',
            'yj.wanMian': '挽面',
            'yj.kaiRong': '开容',
            'yj.xiuFen': '修坟',
            'yj.qiZuan': '启钻',
            'yj.poTu': '破土',
            'yj.anZang': '安葬',
            'yj.liBei': '立碑',
            'yj.chengFu': '成服',
            'yj.chuFu': '除服',
            'yj.kaiShengFen': '开生坟',
            'yj.heShouMu': '合寿木',
            'yj.ruLian': '入殓',
            'yj.yiJiu': '移柩',
            'yj.puDu': '普渡',
            'yj.ruZhai': '入宅',
            'yj.anXiang': '安香',
            'yj.anMen': '安门',
            'yj.xiuZao': '修造',
            'yj.qiJi': '起基',
            'yj.dongTu': '动土',
            'yj.shangLiang': '上梁',
            'yj.shuZhu': '竖柱',
            'yj.kaiJing': '开井开池',
            'yj.zuoBei': '作陂放水',
            'yj.chaiXie': '拆卸',
            'yj.poWu': '破屋',
            'yj.huaiYuan': '坏垣',
            'yj.buYuan': '补垣',
            'yj.faMuZuoLiang': '伐木做梁',
            'yj.zuoZhao': '作灶',
            'yj.jieChu': '解除',
            'yj.kaiZhuYan': '开柱眼',
            'yj.chuanPing': '穿屏扇架',
            'yj.gaiWuHeJi': '盖屋合脊',
            'yj.kaiCe': '开厕',
            'yj.zaoCang': '造仓',
            'yj.saiXue': '塞穴',
            'yj.pingZhi': '平治道涂',
            'yj.zaoQiao': '造桥',
            'yj.zuoCe': '作厕',
            'yj.zhuDi': '筑堤',
            'yj.kaiChi': '开池',
            'yj.faMu': '伐木',
            'yj.kaiQu': '开渠',
            'yj.jueJing': '掘井',
            'yj.saoShe': '扫舍',
            'yj.fangShui': '放水',
            'yj.zaoWu': '造屋',
            'yj.heJi': '合脊',
            'yj.zaoChuChou': '造畜稠',
            'yj.xiuMen': '修门',
            'yj.dingSang': '定磉',
            'yj.zuoLiang': '作梁',
            'yj.xiuShi': '修饰垣墙',
            'yj.jiaMa': '架马',
            'yj.kaiShi': '开市',
            'yj.guaBian': '挂匾',
            'yj.naChai': '纳财',
            'yj.qiuCai': '求财',
            'yj.kaiCang': '开仓',
            'yj.maiChe': '买车',
            'yj.zhiChan': '置产',
            'yj.guYong': '雇庸',
            'yj.chuHuoCai': '出货财',
            'yj.anJiXie': '安机械',
            'yj.zaoCheQi': '造车器',
            'yj.jingLuo': '经络',
            'yj.yunNiang': '酝酿',
            'yj.zuoRan': '作染',
            'yj.guZhu': '鼓铸',
            'yj.zaoChuan': '造船',
            'yj.geMi': '割蜜',
            'yj.zaiZhong': '栽种',
            'yj.quYu': '取渔',
            'yj.jieWang': '结网',
            'yj.muYang': '牧养',
            'yj.anDuiWei': '安碓磑',
            'yj.xiYi': '习艺',
            'yj.ruXue': '入学',
            'yj.liFa': '理发',
            'yj.tanBing': '探病',
            'yj.jianGui': '见贵',
            'yj.chengChuan': '乘船',
            'yj.duShui': '渡水',
            'yj.zhenJiu': '针灸',
            'yj.chuXing': '出行',
            'yj.yiXi': '移徙',
            'yj.fenJu': '分居',
            'yj.TiTou': '剃头',
            'yj.zhengShou': '整手足甲',
            'yj.naChu': '纳畜',
            'yj.buZhuo': '捕捉',
            'yj.tianLie': '畋猎',
            'yj.jiaoNiuMa': '教牛马',
            'yj.huiQinYou': '会亲友',
            'yj.fuRen': '赴任',
            'yj.qiuYi': '求医',
            'yj.zhiBing': '治病',
            'yj.ciSong': '词讼',
            'yj.qiJiDongTu': '起基动土',
            'yj.poWuHuaiYuan': '破屋坏垣',
            'yj.gaiWu': '盖屋',
            'yj.zaoCangKu': '造仓库',
            'yj.liQuanJiaoYi': '立券交易',
            'yj.jiaoYi': '交易',
            'yj.liQuan': '立券',
            'yj.anJi': '安机',
            'yj.huiYou': '会友',
            'yj.qiuYiLiaoBing': '求医疗病',
            'yj.zhuShi': '诸事不宜',
            'yj.yuShi': '馀事勿取',
            'yj.xingSang': '行丧',
            'yj.duanYi': '断蚁',
            'yj.guiXiu': '归岫',
            'xx.bi': '毕',
            'xx.yi': '翼',
            'xx.ji': '箕',
            'xx.kui': '奎',
            'xx.gui': '鬼',
            'xx.di': '氐',
            'xx.xu': '虚',
            'xx.wei': '危',
            'xx.zi': '觜',
            'xx.zhen': '轸',
            'xx.dou': '斗',
            'xx.lou': '娄',
            'xx.liu': '柳',
            'xx.fang': '房',
            'xx.xin': '心',
            'xx.shi': '室',
            'xx.can': '参',
            'xx.jiao': '角',
            'xx.niu': '牛',
            'xx.vei': '胃',
            'xx.xing': '星',
            'xx.zhang': '张',
            'xx.tail': '尾',
            'xx.qiang': '壁',
            'xx.jing': '井',
            'xx.kang': '亢',
            'xx.nv': '女',
            'xx.mao': '昴',
            'sz.chun': '春',
            'sz.xia': '夏',
            'sz.qiu': '秋',
            'sz.dong': '冬',
            'od.first': '孟',
            'od.second': '仲',
            'od.third': '季',
            'yx.shuo': '朔',
            'yx.jiShuo': '既朔',
            'yx.eMeiXin': '蛾眉新',
            'yx.eMei': '蛾眉',
            'yx.xi': '夕',
            'yx.shangXian': '上弦',
            'yx.jiuYe': '九夜',
            'yx.night': '宵',
            'yx.jianYingTu': '渐盈凸',
            'yx.xiaoWang': '小望',
            'yx.wang': '望',
            'yx.jiWang': '既望',
            'yx.liDai': '立待',
            'yx.juDai': '居待',
            'yx.qinDai': '寝待',
            'yx.gengDai': '更待',
            'yx.jianKuiTu': '渐亏凸',
            'yx.xiaXian': '下弦',
            'yx.youMing': '有明',
            'yx.eMeiCan': '蛾眉残',
            'yx.can': '残',
            'yx.xiao': '晓',
            'yx.hui': '晦',
            'ny.sangZhe': '桑柘',
            'ny.baiLa': '白蜡',
            'ny.yangLiu': '杨柳',
            'ny.jinBo': '金箔',
            'ny.haiZhong': '海中',
            'ny.daHai': '大海',
            'ny.shaZhong': '沙中',
            'ny.luZhong': '炉中',
            'ny.shanXia': '山下',
            'ny.daLin': '大林',
            'ny.pingDi': '平地',
            'ny.luPang': '路旁',
            'ny.biShang': '壁上',
            'ny.jianFeng': '剑锋',
            'ny.shanTou': '山头',
            'ny.fuDeng': '覆灯',
            'ny.jianXia': '涧下',
            'ny.tianHe': '天河',
            'ny.chengTou': '城头',
            'ny.daYi': '大驿',
            'ny.chaiChuan': '钗钏',
            'ny.quanZhong': '泉中',
            'ny.daXi': '大溪',
            'ny.wuShang': '屋上',
            'ny.piLi': '霹雳',
            'ny.tianShang': '天上',
            'ny.songBo': '松柏',
            'ny.shiLiu': '石榴',
            'ny.changLiu': '长流'
        },
        'en': {
            'tg.jia': 'Jia',
            'tg.yi': 'Yi',
            'tg.bing': 'Bing',
            'tg.ding': 'Ding',
            'tg.wu': 'Wu',
            'tg.ji': 'Ji',
            'tg.geng': 'Geng',
            'tg.xin': 'Xin',
            'tg.ren': 'Ren',
            'tg.gui': 'Gui',
            'dz.zi': 'Zi',
            'dz.chou': 'Chou',
            'dz.yin': 'Yin',
            'dz.mao': 'Mao',
            'dz.chen': 'Chen',
            'dz.si': 'Si',
            'dz.wu': 'Wu',
            'dz.wei': 'Wei',
            'dz.shen': 'Shen',
            'dz.you': 'You',
            'dz.xu': 'Xu',
            'dz.hai': 'Hai',
            'zx.jian': 'Build',
            'zx.chu': 'Remove',
            'zx.man': 'Full',
            'zx.ping': 'Flat',
            'zx.ding': 'Stable',
            'zx.zhi': 'Hold',
            'zx.po': 'Break',
            'zx.wei': 'Danger',
            'zx.cheng': 'Complete',
            'zx.shou': 'Collect',
            'zx.kai': 'Open',
            'zx.bi': 'Close',
            'jz.jiaZi': 'JiaZi',
            'jz.yiChou': 'YiChou',
            'jz.bingYin': 'BingYin',
            'jz.dingMao': 'DingMao',
            'jz.wuChen': 'WuChen',
            'jz.jiSi': 'JiSi',
            'jz.gengWu': 'GengWu',
            'jz.xinWei': 'XinWei',
            'jz.renShen': 'RenShen',
            'jz.guiYou': 'GuiYou',
            'jz.jiaXu': 'JiaXu',
            'jz.yiHai': 'YiHai',
            'jz.bingZi': 'BingZi',
            'jz.dingChou': 'DingChou',
            'jz.wuYin': 'WuYin',
            'jz.jiMao': 'JiMao',
            'jz.gengChen': 'GengChen',
            'jz.xinSi': 'XinSi',
            'jz.renWu': 'RenWu',
            'jz.guiWei': 'GuiWei',
            'jz.jiaShen': 'JiaShen',
            'jz.yiYou': 'YiYou',
            'jz.bingXu': 'BingXu',
            'jz.dingHai': 'DingHai',
            'jz.wuZi': 'WuZi',
            'jz.jiChou': 'JiChou',
            'jz.gengYin': 'GengYin',
            'jz.xinMao': 'XinMao',
            'jz.renChen': 'RenChen',
            'jz.guiSi': 'GuiSi',
            'jz.jiaWu': 'JiaWu',
            'jz.yiWei': 'YiWei',
            'jz.bingShen': 'BingShen',
            'jz.dingYou': 'DingYou',
            'jz.wuXu': 'WuXu',
            'jz.jiHai': 'JiHai',
            'jz.gengZi': 'GengZi',
            'jz.xinChou': 'XinChou',
            'jz.renYin': 'RenYin',
            'jz.guiMao': 'GuiMao',
            'jz.jiaChen': 'JiaChen',
            'jz.yiSi': 'YiSi',
            'jz.bingWu': 'BingWu',
            'jz.dingWei': 'DingWei',
            'jz.wuShen': 'WuShen',
            'jz.jiYou': 'JiYou',
            'jz.gengXu': 'GengXu',
            'jz.xinHai': 'XinHai',
            'jz.renZi': 'RenZi',
            'jz.guiChou': 'GuiChou',
            'jz.jiaYin': 'JiaYin',
            'jz.yiMao': 'YiMao',
            'jz.bingChen': 'BingChen',
            'jz.dingSi': 'DingSi',
            'jz.wuWu': 'WuWu',
            'jz.jiWei': 'JiWei',
            'jz.gengShen': 'GengShen',
            'jz.xinYou': 'XinYou',
            'jz.renXu': 'RenXu',
            'jz.guiHai': 'GuiHai',
            'sx.rat': 'Rat',
            'sx.ox': 'Ox',
            'sx.tiger': 'Tiger',
            'sx.rabbit': 'Rabbit',
            'sx.dragon': 'Dragon',
            'sx.snake': 'Snake',
            'sx.horse': 'Horse',
            'sx.goat': 'Goat',
            'sx.monkey': 'Monkey',
            'sx.rooster': 'Rooster',
            'sx.dog': 'Dog',
            'sx.pig': 'Pig',
            'dw.long': 'Dragon',
            'dw.niu': 'Ox',
            'dw.gou': 'Dog',
            'dw.yang': 'Goat',
            'dw.tu': 'Rabbit',
            'dw.shu': 'Rat',
            'dw.ji': 'Rooster',
            'dw.ma': 'Horse',
            'dw.hu': 'Tiger',
            'dw.zhu': 'Pig',
            'dw.hou': 'Monkey',
            'dw.she': 'Snake',
            'dw.huLi': 'Fox',
            'dw.yan': 'Swallow',
            'dw.bao': 'Leopard',
            'dw.yuan': 'Ape',
            'dw.yin': 'Earthworm',
            'dw.lu': 'Deer',
            'dw.wu': 'Crow',
            'dw.lang': 'Wolf',
            'dw.fu': 'Bat',
            'wx.jin': 'Metal',
            'wx.mu': 'Wood',
            'wx.shui': 'Water',
            'wx.huo': 'Fire',
            'wx.tu': 'Earth',
            'wx.ri': 'Sun',
            'wx.yue': 'Moon',
            'n.zero': '0',
            'n.one': '1',
            'n.two': '2',
            'n.three': '3',
            'n.four': '4',
            'n.five': '5',
            'n.six': '6',
            'n.seven': '7',
            'n.eight': '8',
            'n.nine': '9',
            'n.ten': '10',
            'n.eleven': '11',
            'n.twelve': '12',
            'w.sun': 'Sunday',
            'w.mon': 'Monday',
            'w.tues': 'Tuesday',
            'w.wed': 'Wednesday',
            'w.thur': 'Thursday',
            'w.fri': 'Friday',
            'w.sat': 'Saturday',
            'xz.aries': 'Aries',
            'xz.taurus': 'Taurus',
            'xz.gemini': 'Gemini',
            'xz.cancer': 'Cancer',
            'xz.leo': 'Leo',
            'xz.virgo': 'Virgo',
            'xz.libra': 'Libra',
            'xz.scorpio': 'Scorpio',
            'xz.sagittarius': 'Sagittarius',
            'xz.capricornus': 'Capricornus',
            'xz.aquarius': 'Aquarius',
            'xz.pisces': 'Pisces',
            'bg.qian': 'Qian',
            'bg.kun': 'Kun',
            'bg.zhen': 'Zhen',
            'bg.xun': 'Xun',
            'bg.kan': 'Kan',
            'bg.li': 'Li',
            'bg.gen': 'Gen',
            'bg.dui': 'Dui',
            'ps.center': 'Center',
            'ps.dong': 'East',
            'ps.nan': 'South',
            'ps.xi': 'West',
            'ps.bei': 'North',
            'ps.zhong': 'Center',
            'ps.zhengDong': 'East',
            'ps.zhengNan': 'South',
            'ps.zhengXi': 'West',
            'ps.zhengBei': 'North',
            'ps.dongBei': 'Northeast',
            'ps.dongNan': 'Southeast',
            'ps.xiBei': 'Northwest',
            'ps.xiNan': 'Southwest',
            'jq.dongZhi': 'Winter Solstice',
            'jq.xiaoHan': 'Lesser Cold',
            'jq.daHan': 'Great Cold',
            'jq.liChun': 'Spring Beginning',
            'jq.yuShui': 'Rain Water',
            'jq.jingZhe': 'Awakening from Hibernation',
            'jq.chunFen': 'Spring Equinox',
            'jq.qingMing': 'Fresh Green',
            'jq.guYu': 'Grain Rain',
            'jq.liXia': 'Beginning of Summer',
            'jq.xiaoMan': 'Lesser Fullness',
            'jq.mangZhong': 'Grain in Ear',
            'jq.xiaZhi': 'Summer Solstice',
            'jq.xiaoShu': 'Lesser Heat',
            'jq.daShu': 'Greater Heat',
            'jq.liQiu': 'Beginning of Autumn',
            'jq.chuShu': 'End of Heat',
            'jq.baiLu': 'White Dew',
            'jq.qiuFen': 'Autumnal Equinox',
            'jq.hanLu': 'Cold Dew',
            'jq.shuangJiang': 'First Frost',
            'jq.liDong': 'Beginning of Winter',
            'jq.xiaoXue': 'Light Snow',
            'jq.daXue': 'Heavy Snow',
            'sn.qingLong': 'Azure Dragon',
            'sn.baiHu': 'White Tiger',
            'sn.zhuQue': 'Rosefinch',
            'sn.xuanWu': 'Black Tortoise',
            'sn.tianEn': 'Serene Grace',
            'sn.siShen': 'Death',
            'sn.tianMa': 'Pegasus',
            'sn.baLong': 'Eight Dragon',
            'sn.jiuHu': 'Nine Tiger',
            'sn.qiNiao': 'Seven Bird',
            'sn.liuShe': 'Six Snake',
            's.none': 'None',
            's.goodLuck': 'Good luck',
            's.badLuck': 'Bad luck',
            's.yin': 'Yin',
            's.yang': 'Yang',
            's.white': 'White',
            's.black': 'Black',
            's.blue': 'Blue',
            's.green': 'Green',
            's.yellow': 'Yellow',
            's.red': 'Red',
            's.purple': 'Purple',
            'jr.chuXi': 'Chinese New Year\'s Eve',
            'jr.chunJie': 'Luna New Year',
            'jr.yuanXiao': 'Lantern Festival',
            'jr.duanWu': 'Dragon Boat Festival',
            'jr.qiXi': 'Begging Festival',
            'jr.zhongQiu': 'Mid-Autumn Festival',
            'jr.laBa': 'Laba Festival',
            'jr.yuanDan': 'New Year\'s Day',
            'jr.qingRen': 'Valentine\'s Day',
            'jr.fuNv': 'Women\'s Day',
            'jr.xiaoFei': 'Consumer Rights Day',
            'jr.zhiShu': 'Arbor Day',
            'jr.wuYi': 'International Worker\'s Day',
            'jr.erTong': 'Children\'s Day',
            'jr.qingNian': 'Youth Day',
            'jr.yuRen': 'April Fools\' Day',
            'jr.jianDang': 'Party\'s Day',
            'jr.jianJun': 'Army Day',
            'jr.jiaoShi': 'Teachers\' Day',
            'jr.guoQing': 'National Day',
            'jr.wanShengYe': 'All Saints\' Eve',
            'jr.wanSheng': 'All Saints\' Day',
            'jr.pingAn': 'Christmas Eve',
            'jr.shengDan': 'Christmas Day',
            'ts.zhan': 'At',
            'ts.hu': 'Household',
            'ts.zao': 'Cooker',
            'ts.dui': 'Pestle',
            'ts.xi': 'Habitat',
            'ts.win': 'Window',
            'ts.fang': 'Room',
            'ts.chuang': 'Bed',
            'ts.lu': 'Stove',
            'ts.mo': 'Mill',
            'ts.chu': 'Kitchen',
            'ts.ce': 'Toilet',
            'ts.cang': 'Depot',
            'ts.cangKu': 'Depot',
            'ts.daMen': 'Gate',
            'ts.men': 'Door',
            'ts.tang': 'Hall',
            'ly.xianSheng': 'Win first',
            'ly.xianFu': 'Lose first',
            'ly.youYin': 'Friend\'s referral',
            'ly.foMie': 'Buddhism\'s demise',
            'ly.daAn': 'Great safety',
            'ly.chiKou': 'Chikagoro',
            'yj.jiSi': 'Sacrifice',
            'yj.qiFu': 'Pray',
            'yj.qiuSi': 'Seek heirs',
            'yj.kaiGuang': 'Consecretion',
            'yj.suHui': 'Paint sculptural',
            'yj.qiJiao': 'Build altar',
            'yj.zhaiJiao': 'Taoist rites',
            'yj.muYu': 'Bathing',
            'yj.chouShen': 'Reward gods',
            'yj.zaoMiao': 'Build temple',
            'yj.siZhao': 'Offer kitchen god',
            'yj.fenXiang': 'Burn incense',
            'yj.xieTu': 'Earth gratitude',
            'yj.chuHuo': 'Expel the flame',
            'yj.diaoKe': 'Carving',
            'yj.jiaQu': 'Marriage',
            'yj.DingHun': 'Engagement',
            'yj.naCai': 'Proposing',
            'yj.wenMing': 'Ask name',
            'yj.naXu': 'Uxorilocal marriage',
            'yj.guiNing': 'Visit parents',
            'yj.anChuang': 'Bed placing',
            'yj.heZhang': 'Make up accounts',
            'yj.guanJi': 'Crowning adulthood',
            'yj.dingMeng': 'Make alliance',
            'yj.jinRenKou': 'Adopt',
            'yj.caiYi': 'Dressmaking',
            'yj.wanMian': 'Cosmeticsurgery',
            'yj.kaiRong': 'Open face',
            'yj.xiuFen': 'Grave repair',
            'yj.qiZuan': 'Open coffin',
            'yj.poTu': 'Break earth',
            'yj.anZang': 'Burial',
            'yj.liBei': 'Tombstone erecting',
            'yj.chengFu': 'Formation of clothes',
            'yj.chuFu': 'Mourning clothes removal',
            'yj.kaiShengFen': 'Open grave',
            'yj.heShouMu': 'Make coffin',
            'yj.ruLian': 'Body placing',
            'yj.yiJiu': 'Move coffin',
            'yj.puDu': 'Save soul',
            'yj.ruZhai': 'Enter house',
            'yj.anXiang': 'Incenst placement',
            'yj.anMen': 'Door placing',
            'yj.xiuZao': 'Repair',
            'yj.qiJi': 'Digging',
            'yj.dongTu': 'Break ground',
            'yj.shangLiang': 'Beam placing',
            'yj.shuZhu': 'Erecting pillars',
            'yj.kaiJing': 'Open pond and well',
            'yj.zuoBei': 'Make pond and fill water',
            'yj.chaiXie': 'Smash house',
            'yj.poWu': 'Break house',
            'yj.huaiYuan': 'Demolish',
            'yj.buYuan': 'Mending',
            'yj.faMuZuoLiang': 'Make beams',
            'yj.zuoZhao': 'Make stove',
            'yj.jieChu': 'Removal',
            'yj.kaiZhuYan': 'Build beam',
            'yj.chuanPing': 'Build door',
            'yj.gaiWuHeJi': 'Cover house',
            'yj.kaiCe': 'Open toilet',
            'yj.zaoCang': 'Build depot',
            'yj.saiXue': 'Block nest',
            'yj.pingZhi': 'Repair roads',
            'yj.zaoQiao': 'Build bridge',
            'yj.zuoCe': 'Build toilet',
            'yj.zhuDi': 'Fill',
            'yj.kaiChi': 'Open pond',
            'yj.faMu': 'Lumbering',
            'yj.kaiQu': 'Canalization',
            'yj.jueJing': 'Dig well',
            'yj.saoShe': 'Sweep house',
            'yj.fangShui': 'Drainage',
            'yj.zaoWu': 'Build house',
            'yj.heJi': 'Close ridge',
            'yj.zaoChuChou': 'Livestock thickening',
            'yj.xiuMen': 'Repair door',
            'yj.dingSang': 'Fix stone',
            'yj.zuoLiang': 'Beam construction',
            'yj.xiuShi': 'Decorate wall',
            'yj.jiaMa': 'Erect horse',
            'yj.kaiShi': 'Opening',
            'yj.guaBian': 'Hang plaque',
            'yj.naChai': 'Accept wealth',
            'yj.qiuCai': 'Seek wealth',
            'yj.kaiCang': 'Open depot',
            'yj.maiChe': 'Buy car',
            'yj.zhiChan': 'Buy property',
            'yj.guYong': 'Hire',
            'yj.chuHuoCai': 'Delivery',
            'yj.anJiXie': 'Build machine',
            'yj.zaoCheQi': 'Build car',
            'yj.jingLuo': 'Build loom',
            'yj.yunNiang': 'Brew',
            'yj.zuoRan': 'Dye',
            'yj.guZhu': 'Cast',
            'yj.zaoChuan': 'Build boat',
            'yj.geMi': 'Harvest honey',
            'yj.zaiZhong': 'Farming',
            'yj.quYu': 'Fishing',
            'yj.jieWang': 'Netting',
            'yj.muYang': 'Graze',
            'yj.anDuiWei': 'Build rub',
            'yj.xiYi': 'Learn',
            'yj.ruXue': 'Enter school',
            'yj.liFa': 'Haircut',
            'yj.tanBing': 'Visiting',
            'yj.jianGui': 'Meet noble',
            'yj.chengChuan': 'Ride boat',
            'yj.duShui': 'Cross water',
            'yj.zhenJiu': 'Acupuncture',
            'yj.chuXing': 'Travel',
            'yj.yiXi': 'Move',
            'yj.fenJu': 'Live apart',
            'yj.TiTou': 'Shave',
            'yj.zhengShou': 'Manicure',
            'yj.naChu': 'Feed livestock',
            'yj.buZhuo': 'Catch',
            'yj.tianLie': 'Hunt',
            'yj.jiaoNiuMa': 'Train horse',
            'yj.huiQinYou': 'Meet friends',
            'yj.fuRen': 'Go post',
            'yj.qiuYi': 'See doctor',
            'yj.zhiBing': 'Treat',
            'yj.ciSong': 'Litigation',
            'yj.qiJiDongTu': 'Lay foundation',
            'yj.poWuHuaiYuan': 'Demolish',
            'yj.gaiWu': 'Build house',
            'yj.zaoCangKu': 'Build depot',
            'yj.liQuanJiaoYi': 'Covenant trade',
            'yj.jiaoYi': 'Trade',
            'yj.liQuan': 'Covenant',
            'yj.anJi': 'Install machine',
            'yj.huiYou': 'Meet friends',
            'yj.qiuYiLiaoBing': 'Seek treatment',
            'yj.zhuShi': 'Everything Sucks',
            'yj.yuShi': 'Do nothing else',
            'yj.xingSang': 'Funeral',
            'yj.duanYi': 'Block ant hole',
            'yj.guiXiu': 'Place beam',
            'xx.bi': 'Finish',
            'xx.yi': 'Wing',
            'xx.ji': 'Sieve',
            'xx.kui': 'Qui',
            'xx.gui': 'Ghost',
            'xx.di': 'Foundation',
            'xx.xu': 'Virtual',
            'xx.wei': 'Danger',
            'xx.zi': 'Mouth',
            'xx.zhen': 'Cross-bar',
            'xx.dou': 'Fight',
            'xx.lou': 'Weak',
            'xx.liu': 'Willow',
            'xx.fang': 'House',
            'xx.xin': 'Heart',
            'xx.shi': 'Room',
            'xx.can': 'Join',
            'xx.jiao': 'Horn',
            'xx.niu': 'Ox',
            'xx.vei': 'Stomach',
            'xx.xing': 'Star',
            'xx.zhang': 'Chang',
            'xx.tail': 'Tail',
            'xx.qiang': 'Wall',
            'xx.jing': 'Well',
            'xx.kang': 'Kang',
            'xx.nv': 'Female',
            'xx.mao': 'Mao',
            'sz.chun': 'Spring',
            'sz.xia': 'Summer',
            'sz.qiu': 'Autumn',
            'sz.dong': 'Winter',
            'yx.shuo': 'New',
            'yx.eMeiXin': 'New waxing',
            'yx.eMei': 'Waxing',
            'yx.xi': 'Evening',
            'yx.shangXian': 'First quarter',
            'yx.jiuYe': 'Nine night',
            'yx.night': 'Night',
            'yx.jianYingTu': 'Gibbous',
            'yx.xiaoWang': 'Little full',
            'yx.wang': 'Full',
            'yx.jianKuiTu': 'Disseminating',
            'yx.xiaXian': 'Third quarter',
            'yx.eMeiCan': 'Waning waxing',
            'yx.can': 'Waning',
            'yx.xiao': 'Daybreak',
            'yx.hui': 'Obscure',
            'ny.sangZhe': 'Cudrania',
            'ny.baiLa': 'Wax',
            'ny.yangLiu': 'Willow',
            'ny.jinBo': 'Foil',
            'ny.haiZhong': 'Sea',
            'ny.daHai': 'Ocean',
            'ny.shaZhong': 'Sand',
            'ny.luZhong': 'Stove',
            'ny.shanXia': 'Piedmont',
            'ny.daLin': 'Forest',
            'ny.pingDi': 'Land',
            'ny.luPang': 'Roadside',
            'ny.biShang': 'Wall',
            'ny.jianFeng': 'Blade',
            'ny.shanTou': 'Hilltop',
            'ny.fuDeng': 'Light',
            'ny.jianXia': 'Valleyn',
            'ny.tianHe': 'River',
            'ny.chengTou': 'City',
            'ny.daYi': 'Post',
            'ny.chaiChuan': 'Ornaments',
            'ny.quanZhong': 'Spring',
            'ny.daXi': 'Stream',
            'ny.wuShang': 'Roof',
            'ny.piLi': 'Thunderbolt',
            'ny.tianShang': 'Sky',
            'ny.songBo': 'Coniferin',
            'ny.shiLiu': 'Pomegranate',
            'ny.changLiu': 'Flows'
        }
    };

    private static _OBJS: { [key: string]: LunarUtil | SolarUtil | TaoUtil | FotoUtil | NineStarUtil } = {
        'LunarUtil': LunarUtil,
        'SolarUtil': SolarUtil,
        'TaoUtil': TaoUtil,
        'FotoUtil': FotoUtil,
        'NineStarUtil': NineStarUtil
    };

    private static _DICT_STRING: { [key: string]: { [key: string]: { [key: string]: string } } } = {
        'LunarUtil': {
            'TIAN_SHEN_TYPE': {},
            'TIAN_SHEN_TYPE_LUCK': {},
            'XIU_LUCK': {},
            'LU': {},
            'XIU': {},
            'SHA': {},
            'POSITION_DESC': {},
            'NAYIN': {},
            'WU_XING_GAN': {},
            'WU_XING_ZHI': {},
            'SHOU': {},
            'GONG': {},
            'FESTIVAL': {},
            'ZHENG': {},
            'ANIMAL': {},
            'SHI_SHEN': {},
            'XIU_SONG': {}
        },
        'SolarUtil': {
            'FESTIVAL': {}
        },
        'TaoUtil': {
            'BA_HUI': {},
            'BA_JIE': {}
        }
    };

    private static _DICT_NUMBER: { [key: string]: { [key: string]: { [key: string]: number } } } = {
        'LunarUtil': {
            'ZHI_TIAN_SHEN_OFFSET': {},
            'CHANG_SHENG_OFFSET': {}
        }
    };

    private static _DICT_ARRAY: { [key: string]: { [key: string]: { [key: string]: string[] } } } = {
        'LunarUtil': {
            'ZHI_HIDE_GAN': {}
        }
    };

    private static _ARRAYS: { [key: string]: { [key: string]: Array<string> } } = {
        'LunarUtil': {
            'GAN': [],
            'ZHI': [],
            'JIA_ZI': [],
            'ZHI_XING': [],
            'XUN': [],
            'XUN_KONG': [],
            'CHONG': [],
            'CHONG_GAN': [],
            'CHONG_GAN_TIE': [],
            'HE_GAN_5': [],
            'HE_ZHI_6': [],
            'SHENGXIAO': [],
            'NUMBER': [],
            'POSITION_XI': [],
            'POSITION_YANG_GUI': [],
            'POSITION_YIN_GUI': [],
            'POSITION_FU': [],
            'POSITION_FU_2': [],
            'POSITION_CAI': [],
            'POSITION_TAI_SUI_YEAR': [],
            'POSITION_GAN': [],
            'POSITION_ZHI': [],
            'JIE_QI': [],
            'JIE_QI_IN_USE': [],
            'TIAN_SHEN': [],
            'SHEN_SHA': [],
            'PENGZU_GAN': [],
            'PENGZU_ZHI': [],
            'MONTH_ZHI': [],
            'CHANG_SHENG': [],
            'HOU': [],
            'WU_HOU': [],
            'POSITION_TAI_DAY': [],
            'POSITION_TAI_MONTH': [],
            'YI_JI': [],
            'LIU_YAO': [],
            'MONTH': [],
            'SEASON': [],
            'DAY': [],
            'YUE_XIANG': []
        },
        'SolarUtil': {
            'WEEK': [],
            'XINGZUO': []
        },
        'TaoUtil': {
            'AN_WU': []
        },
        'FotoUtil': {
            'XIU_27': []
        },
        'NineStarUtil': {
            'NUMBER': [],
            'WU_XING': [],
            'POSITION': [],
            'LUCK_XUAN_KONG': [],
            'YIN_YANG_QI_MEN': [],
            'COLOR': []
        }
    };

    private static updateArray(c: string) {
        if (!I18n._ARRAYS[c] || !I18n._OBJS[c]) {
            return;
        }
        const v = I18n._ARRAYS[c];
        const o = I18n._OBJS[c];
        for (const k in v) {
            if (!isKey(k, o)) continue;
            const arr = v[k];
            const obj: { [key: string]: string } = o[k];
            for (let i = 0, j = arr.length; i < j; i++) {
                obj[i]  = arr[i].replace(/{(.[^}]*)}/g, ($0: string, $1: string) => {
                    return I18n.getMessage($1);
                });
            }
        }
    }

    private static updateStringDictionary(c: string) {
        const v = I18n._DICT_STRING[c];
        const o = I18n._OBJS[c];
        for (const k in v) {
            if (!isKey(k, o)) continue;
            const dict: { [key: string]: string } = v[k];
            for (const key in dict) {
                const i = key.replace(/{(.[^}]*)}/g, ($0: string, $1: string) => {
                    return I18n.getMessage($1);
                });
                const obj: { [key: string]: string } = o[k];
                obj[i] = dict[key].replace(/{(.[^}]*)}/g, ($0: string, $1: string) => {
                    return I18n.getMessage($1);
                });
            }
        }
    }

    private static updateNumberDictionary(c: string) {
        const v = I18n._DICT_NUMBER[c];
        const o = I18n._OBJS[c];
        for (const k in v) {
            if (!isKey(k, o)) continue;
            const dict: { [key: string]: number } = v[k];
            for (const key in dict) {
                const i = key.replace(/{(.[^}]*)}/g, ($0: string, $1: string) => {
                    return I18n.getMessage($1);
                });
                const obj: { [key: string]: number } = o[k];
                obj[i] = dict[key];
            }
        }
    }

    private static updateArrayDictionary(c: string) {
        const v = I18n._DICT_ARRAY[c];
        const o = I18n._OBJS[c];
        for (const k in v) {
            if (!isKey(k, o)) continue;
            const dict: { [key: string]: string[] } = v[k];
            for (const key in dict) {
                const x = key.replace(/{(.[^}]*)}/g, ($0: string, $1: string) => {
                    return I18n.getMessage($1);
                });
                const arr = dict[key];
                for (let i = 0, j = arr.length; i < j; i++) {
                    arr[i] = arr[i].replace(/{(.[^}]*)}/g, ($0: string, $1: string) => {
                        return I18n.getMessage($1);
                    });
                }
                const obj: { [key: string]: string[] } = o[k];
                obj[x] = arr;
            }
        }
    }

    private static update() {
        for (const c in I18n._ARRAYS) {
            I18n.updateArray(c);
        }
        for (const c in I18n._DICT_STRING) {
            I18n.updateStringDictionary(c);
        }
        for (const c in I18n._DICT_NUMBER) {
            I18n.updateNumberDictionary(c);
        }
        for (const c in I18n._DICT_ARRAY) {
            I18n.updateArrayDictionary(c);
        }
    }

    static setMessages(lang: string, messages: { [key: string]: string }) {
        if (!messages) {
            return;
        }
        if (!I18n._MESSAGES[lang]) {
            I18n._MESSAGES[lang] = {};
        }
        for (const key in messages) {
            I18n._MESSAGES[lang][key] = messages[key];
        }
        I18n.update();
    }

    static getMessage(key: string): string {
        let s = I18n._MESSAGES[I18n._LANG][key];
        if (undefined == s) {
            s = I18n._MESSAGES[I18n._DEFAULT_LANG][key];
        }
        if (undefined == s) {
            s = key;
        }
        return s;
    }

    static setLanguage(lang: string) {
        if (I18n._MESSAGES[lang]) {
            I18n._LANG = lang;
            I18n.update();
        }
    }

    static getLanguage(): string {
        return I18n._LANG;
    }

    private static initArray(c: string) {
        const v = I18n._ARRAYS[c];
        const o = I18n._OBJS[c];
        for (const k in v) {
            v[k].length = 0;
            if (!isKey(k, o)) continue;
            const arr: { [key: string]: never } = o[k];
            for (let i = 0, j = arr.length; i < j; i++) {
                v[k].push(arr[i]);
            }
        }
    }

    private static initArrayDictionary(c: string) {
        const v = I18n._DICT_ARRAY[c];
        const o = I18n._OBJS[c];
        for (const k in v) {
            if (!isKey(k, o)) continue;
            const dict: { [key: string]: string[] } = o[k];
            for (const key in dict) {
                v[k][key] = dict[key];
            }
        }
    }

    private static initStringDictionary(c: string) {
        const v = I18n._DICT_STRING[c];
        const o = I18n._OBJS[c];
        for (const k in v) {
            if (!isKey(k, o)) continue;
            const dict: { [key: string]: string } = o[k];
            for (const key in dict) {
                v[k][key] = dict[key];
            }
        }
    }

    private static initNumberDictionary(c: string) {
        const v = I18n._DICT_NUMBER[c];
        const o = I18n._OBJS[c];
        for (const k in v) {
            if (!isKey(k, o)) continue;
            const dict: { [key: string]: number } = o[k];
            for (const key in dict) {
                v[k][key] = dict[key];
            }
        }
    }

    static init() {
        if (I18n._INIT) {
            return;
        }
        I18n._INIT = true;
        for (const c in I18n._ARRAYS) {
            I18n.initArray(c);
        }
        for (const c in I18n._DICT_STRING) {
            I18n.initStringDictionary(c);
        }
        for (const c in I18n._DICT_NUMBER) {
            I18n.initNumberDictionary(c);
        }
        for (const c in I18n._DICT_ARRAY) {
            I18n.initArrayDictionary(c);
        }
        I18n.setLanguage(I18n._DEFAULT_LANG);
    }

}
